<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="400px"
        min-width="400px"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ $t('message.sort') + ": " + $t('message.docFooterTypes.' + documentFooterType) }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-row no-gutters class="pl-1 pr-3 pt-3">
                    <draggable
                        ghost-class="ghost"
                        v-model="sortedList"
                    >
                        <template v-for="(item, index) in sortedList">
                            <div :key="item.item_id" class="d-flex flex-row align-center">
                                <span class="mr-2 font-weight-bold">{{ '#' + (index + 1).toString() }}</span>
                                <div :class="'sort-document' + (item.item_id == documentFooterId ? ' success lighten-2' : '')" style="cursor: move">{{ item.text }}</div>
                            </div>
                        </template>
                    </draggable>
                </v-row>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveDocumentFooterSorting()"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from 'vuedraggable';
import { api } from "Api";
import {log} from "Helpers/helpers";

export default {
    name: "UpdateDocumentFooterSorting",
    components: { draggable },
    props: ['documentFooter','documentFooterType','documentFooterId','updateMode','dialog','pageKey'],
    data() {
        return {
            currentItemDragged: null,
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            sortedList: []
        }
    },
    computed: {
        ...mapGetters('documentfooter',{
            contractFooterNotes: 'contractFooterNotes',
            coverNoteFooterNotes: 'coverNoteFooterNotes',
            invoiceFooterNotes: 'invoiceFooterNotes',
            altInvoiceFooterNotes: 'altInvoiceFooterNotes',
            localSalesInvoiceFooterNotes: 'localSalesInvoiceFooterNotes',
            lcaConditions: 'lcaConditions',
            lcaNotes: 'lcaNotes',
        }),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        getDocumentFooterSorting() {
            return new Promise((resolve, reject) => {
                try {
                    let list = []
                    switch(this.documentFooterType){
                        case 'contract':
                            list = this.contractFooterNotes
                            break;
                        case 'invoice':
                            list = this.invoiceFooterNotes
                            break;
                        case 'localsalesinvoice':
                            list = this.localSalesInvoiceFooterNotes
                            break;
                        case 'altinvoice':
                            list = this.altInvoiceFooterNotes
                            break;
                        case 'covernote':
                            list = this.coverNoteFooterNotes
                            break;
                        case 'lcanote':
                            list = this.lcaNotes
                            break;
                        case 'lcacondition':
                            list = this.lcaConditions
                            break;
                    }
                    list.sort((a, b) => {
                        if(a.sort_id > b.sort_id) return 1
                        if(a.sort_id < b.sort_id) return -1
                        return 0
                    })
                    resolve(list)
                } catch(err) {
                    reject(err)
                }
            })
        },
        saveDocumentFooterSorting() {
            const sortList = this.sortedList.map( (item, index) => (
                {
                    sortId: index + 1,
                    footer: item.text,
                    id: item.item_id
                }
            ))
            const type = this.documentFooterType
            return new Promise((resolve, reject) => {
                api
                    .put('/document-footers/' + this.documentFooterId + '/sorting', {
                        sortList: sortList,
                        type: type
                    })
                    .then((status) => {
                        if(status.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.documentFooterSortingUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.documentFooterSortingNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                        resolve('done')
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('message.errors.documentFooterSortingNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                        reject(err)
                    })
            })
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getDocumentFooterSorting()
                        .then(data => {
                            this.sortedList = data
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                } else {
                    // this.sortedList = this.allDocuments[this.standardShippingDocumentCategory]
                }
                this.loading.get = false
            } else {
                this.sortedList = []
            }
            this.edit_dialog = value
        }
    }
}
</script>

<style scoped>
.sort-document {
    cursor: move;
    padding: 5px 10px;
    margin-bottom: 5px;
    border: dashed 1px grey;
    color: black;
    background-color: lightgray;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>